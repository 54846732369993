import React from 'react';
import st from './index.module.css';
import classNames from 'classnames';

const Radio = ({ name, options, selectedOption, onChange, error }) => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1">
      {options.map((option, index) => {
        const inputId = `${name}-${option.value}-${index}`;
        const cl = classNames(st['radio-item']);

        return (
          <div key={option.value} className={cl}>
            <input id={inputId} type="radio" name={name} value={option.value} checked={selectedOption === option.value} onChange={onChange} />
            <label htmlFor={inputId} className="inline-flex items-center mr-4 mb-2">
              <span className="ml-2">{option.label}</span>
            </label>
          </div>
        );
      })}
      {error && <span className="text-red-600 animate-warning">{error}</span>}
    </div>
  );
};

export default Radio;
